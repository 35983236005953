import {
	Box,
	Dialog,
	DialogContent,
	Stack,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import _ from 'lodash';

import images from 'assets/images';

// ----------------------------------------------------------------------

const StyledCloseBox = styled(Box)(() => ({
	position: 'absolute',
	bottom: '-56px',
	left: 'calc(50% - 14px)',
	zIndex: 999,
	cursor: 'pointer'
}));

// ----------------------------------------------------------------------

const ResponsiveModal = ({ open, setOpen, onClose, title, children }) => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const handleClose = () => {
		if (_.isFunction(onClose)) {
			onClose();
		} else {
			setOpen(false);
		}
	};

	// ----------------------------------------------------------------------

	if (matchUpMd) {
		return (
			<Dialog
				maxWidth={'md'}
				fullWidth
				open={open}
				onClose={handleClose}
				PaperProps={{
					sx: {
						borderRadius: '24px'
					}
				}}
			>
				<DialogContent sx={{ padding: '40px 50px' }}>
					<Stack
						flexDirection={'row'}
						alignItems={'center'}
						justifyContent={'space-between'}
						mb={'24px'}
					>
						<Typography variant={'h7'}>{title}</Typography>
						<Box sx={{ cursor: 'pointer' }} onClick={handleClose}>
							<img src={images.CancelBlack} alt={'ic_cancel'} width={36} height={36} />
						</Box>
					</Stack>
					{children}
				</DialogContent>
			</Dialog>
		);
	}

	return (
		<Dialog
			fullWidth
			open={open}
			onClose={handleClose}
			PaperProps={{
				sx: {
					overflow: 'inherit',
					borderRadius: '20px',
					margin: 0,
					maxHeight: 'calc(100% - 76px * 2 - 24px * 2 - 28px)',
					maxWidth: 'calc(100% - 20px * 2)'
				}
			}}
		>
			<DialogContent sx={{ padding: '28px 24px' }}>
				<Stack mb={'12px'}>
					<Typography variant={'h13'}>{title}</Typography>
				</Stack>
				{children}
			</DialogContent>

			<StyledCloseBox onClick={handleClose}>
				<img src={images.CancelWhite} alt={'ic_cancel'} width={28} height={28} />
			</StyledCloseBox>
		</Dialog>
	);
};

export default ResponsiveModal;
