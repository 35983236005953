import { useState } from 'react';
import {
	Box,
	ButtonBase,
	Container,
	Grid,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

import { SMA_AWARD_RANKING } from 'views/sma/constants';
import { ResponsiveImage } from 'components';

// ----------------------------------------------------------------------

const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.white[1]
}));

const StyledNomineeBox = styled(Box)(({ matchUpMd }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '70px',
	marginTop: matchUpMd ? '20px' : '32px'
}));

// ----------------------------------------------------------------------

const SmaRanking01Component = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const [currentId, setCurrentId] = useState(1);

	const handleClickNominee = (item) => {
		setCurrentId(item.id);
	};

	// ----------------------------------------------------------------------

	return (
		<StyledBox>
			<Container
				maxWidth={1200}
				sx={{ pt: matchUpMd ? '80px' : '60px', pb: matchUpMd ? '100px' : '60px' }}
			>
				<Box display={'flex'} justifyContent={'center'}>
					{matchUpMd ? (
						<Typography variant={'h7'} textAlign={'center'}>
							서울가요대상 이달의 아티스트
							<br />
							순위발표
						</Typography>
					) : (
						<Typography variant={'h13'} textAlign={'center'}>
							서울가요대상
							<br />
							이달의 아티스트 순위발표
						</Typography>
					)}
				</Box>
				<StyledNomineeBox matchUpMd={matchUpMd}>
					{SMA_AWARD_RANKING.map((item, index) => (
						<ButtonBase
							key={`sma-award-nominee-${item.id}`}
							className={
								item.id === currentId
									? matchUpMd
										? 'SubIndexItem-Active'
										: 'M-SubIndexItem-Active'
									: matchUpMd
									? 'SubIndexItem-Inactive'
									: 'M-SubIndexItem-Inactive'
							}
							disableRipple
							onClick={() => handleClickNominee(item)}
						>
							{item.nomineeTitle}
						</ButtonBase>
					))}
				</StyledNomineeBox>

				<Grid container>
					{currentId === SMA_AWARD_RANKING[0].id ? (
						<ResponsiveImage
							src={matchUpMd ? images.SmaWorldTrendNovPc : images.SmaWorldTrendNovMobile}
							alt={'sma_world_trend_nov'}
							width={matchUpMd ? 1200 : 750}
							height={matchUpMd ? 1955 : 1800}
						/>
					) : (
						<ResponsiveImage
							src={matchUpMd ? images.SmaFanChoiceNovPc : images.SmaFanChoiceNovMobile}
							alt={'sma_fan_choice_nov'}
							width={matchUpMd ? 1200 : 750}
							height={matchUpMd ? 1955 : 1800}
						/>
					)}
				</Grid>
			</Container>
		</StyledBox>
	);
};

export default SmaRanking01Component;
