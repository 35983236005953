import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const ResponsiveImage = ({ src, alt, width, height, wrapperSx, imgSx }) => {
	const pt = `calc(${height} / ${width} * 100%)`;

	return (
		<Box
			sx={{
				position: 'relative',
				width: '100%',
				height: 0,
				pt: pt,
				...wrapperSx
			}}
		>
			<Box
				component={'img'}
				src={src}
				alt={alt}
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					...imgSx
				}}
			/>
		</Box>
	);
};

export default ResponsiveImage;
