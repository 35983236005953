import {
	Box,
	Container,
	Divider,
	Grid,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

import { ResponsiveImage } from 'components';

import { VOTE_AWARD } from 'views/sma/constants';

// ----------------------------------------------------------------------

const StyledVoteAwardBox = styled(Box)(({ theme, matchUpMd }) => ({
	backgroundColor: theme.palette.white[1],
	borderColor: theme.palette.grey[6],
	borderWidth: 1,
	borderStyle: 'solid',
	...(matchUpMd && {
		borderRadius: 24,
		padding: '32px 28px',
		display: 'flex',
		flexDirection: 'column'
	}),
	...(!matchUpMd && {
		borderRadius: 20,
		padding: '20px 24px',
		display: 'flex',
		flexDirection: 'column'
	})
}));

const StyledVotePeriodBox = styled(Box)(({ theme, matchUpMd }) => ({
	backgroundColor: theme.palette.grey[5],
	borderColor: theme.palette.grey[5],
	borderWidth: 1,
	borderStyle: 'solid',
	...(matchUpMd && {
		borderRadius: 24,
		padding: '22px 28px 33px 28px'
	}),
	...(!matchUpMd && {
		borderRadius: 20,
		padding: '16px 26px 24px 26px'
	})
}));

// ----------------------------------------------------------------------

const Sma05Component = ({ handleOpenPolicyModal }) => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	if (matchUpMd) {
		return (
			<Box sx={{ pt: '40px', pb: '100px' }}>
				<Container maxWidth={1200}>
					<Grid container>
						<Grid item display={'flex'} alignItems={'center'}>
							<img src={images.SmaZoom} alt={'ic_zoom'} width={26} height={26} />
							<Typography variant={'h12'} ml={'6px'}>
								부문별로 투표기간이 달라요!
							</Typography>
						</Grid>
					</Grid>

					<Grid container mt={'16px'}>
						<Grid item>
							<Typography
								variant={'caption2'}
								fontWeight={500}
								color={theme.palette.grey[1]}
							>
								서울가요대상 이달의 아티스트는 2개 부문으로 나뉘어 투표가 진행됩니다.
							</Typography>
						</Grid>
					</Grid>

					<Grid container mt={'28px'} columnSpacing={3}>
						{VOTE_AWARD.map((item) => (
							<Grid item md={3} key={`vote_award_card_${item.id}`}>
								<StyledVoteAwardBox matchUpMd={matchUpMd}>
									<Box alignSelf={'center'}>
										<Typography
											variant={'caption7'}
											fontWeight={400}
											color={theme.palette.grey[1]}
											bgcolor={theme.palette.grey[4]}
											padding={'6px 14px'}
											borderRadius={15}
										>
											{item.tagText}
										</Typography>
									</Box>
									<Box mt={'8px'} alignSelf={'center'}>
										<Typography variant={'h13'} fontWeight={700}>
											{item.title}
										</Typography>
									</Box>
									<Box mt={'4px'} alignSelf={'center'}>
										<Typography variant={'caption3'} fontWeight={400}>
											{item.subtitle}
										</Typography>
									</Box>

									<Box padding={item.imageSize.md.padding}>
										<ResponsiveImage
											src={item.image}
											alt={`ic_vote_award_${item.id}`}
											width={item.imageSize.md.width}
											height={item.imageSize.md.height}
										/>
									</Box>

									<Divider sx={{ borderColor: theme.palette.grey[6] }} />

									<Box mt={'22px'} alignSelf={'center'}>
										<Typography
											variant={'caption5'}
											fontWeight={400}
											color={theme.palette.grey[2]}
											display={'block'}
											textAlign={'center'}
										>
											투표기간
										</Typography>
										<Typography
											variant={'caption1'}
											fontWeight={500}
											color={theme.palette.black[1]}
											display={'block'}
											textAlign={'center'}
											mt={'6px'}
										>
											{item.periodText}
										</Typography>
									</Box>
								</StyledVoteAwardBox>
							</Grid>
						))}
						<Grid item md={6}>
							<StyledVotePeriodBox matchUpMd={matchUpMd}>
								<ResponsiveImage
									src={images.SmaVotePeriodPc}
									alt={'vote_period'}
									width={532}
									height={248}
								/>
								<Divider sx={{ mt: '12px', borderColor: theme.palette.grey[6] }} />

								<Box mt={'22px'} alignSelf={'center'}>
									<Typography
										variant={'caption5'}
										fontWeight={400}
										color={theme.palette.grey[2]}
										display={'block'}
										textAlign={'center'}
									>
										결과발표
									</Typography>
									<Typography
										variant={'caption1'}
										fontWeight={500}
										color={theme.palette.black[1]}
										display={'block'}
										textAlign={'center'}
										mt={'6px'}
									>
										다음 달 1주차 2개 부문 수상자 동시 발표
									</Typography>
								</Box>
							</StyledVotePeriodBox>
						</Grid>
					</Grid>

					<Grid container>
						<Typography
							variant={'caption5'}
							color={theme.palette.grey[2]}
							mt={'12px'}
							sx={{ cursor: 'pointer', textDecoration: 'underline' }}
							onClick={handleOpenPolicyModal}
						>
							투표 정책 자세히 알아보기 >>
						</Typography>
					</Grid>
				</Container>
			</Box>
		);
	}

	return (
		<Box sx={{ pt: '30px', pb: '32px', px: '20px' }}>
			<Container maxWidth={1200}>
				<Grid container>
					<Grid item display={'flex'} alignItems={'center'}>
						<img src={images.SmaZoom} alt={'ic_zoom'} width={18} height={18} />
						<Typography
							variant={'caption2'}
							ml={'10px'}
							color={theme.palette.black[1]}
							fontWeight={700}
						>
							부문별로 투표기간이 달라요!
						</Typography>
					</Grid>
				</Grid>

				<Grid container mt={'12px'} pl={'28px'}>
					<Grid item>
						<Typography
							variant={'caption4'}
							fontWeight={500}
							color={theme.palette.grey[1]}
						>
							서울가요대상 이달의 아티스트는 2개 부문으로 나뉘어 투표가 진행됩니다.
						</Typography>
					</Grid>
				</Grid>

				<Grid container pt={'20px'} rowSpacing={'12px'}>
					{VOTE_AWARD.map((item) => (
						<Grid
							item
							md={3}
							key={`vote_award_card_${item.id}`}
							pl={'28px'}
							width={'100%'}
						>
							<StyledVoteAwardBox matchUpMd={matchUpMd}>
								<Box>
									<Typography
										variant={'caption7'}
										fontWeight={400}
										color={theme.palette.grey[1]}
										bgcolor={theme.palette.grey[4]}
										padding={'5px 10px'}
										borderRadius={15}
									>
										{item.tagText}
									</Typography>
								</Box>
								<Box mt={'8px'}>
									<Typography variant={'h14'} fontWeight={700}>
										{item.title}
									</Typography>
								</Box>
								<Box mt={'4px'}>
									<Typography variant={'caption5'} fontWeight={400}>
										{item.subtitle}
									</Typography>
								</Box>

								<Box alignSelf={'flex-end'} padding={item.imageSize.sm.padding}>
									<img
										src={item.image}
										alt={`ic_vote_award_${item.id}`}
										{...item.imageSize.sm}
									/>
								</Box>

								<Divider sx={{ borderColor: theme.palette.grey[6] }} />

								<Box mt={'20px'} alignSelf={'center'}>
									<Typography
										variant={'caption7'}
										fontWeight={400}
										color={theme.palette.grey[2]}
										display={'block'}
										textAlign={'center'}
									>
										투표기간
									</Typography>
									<Typography
										variant={'caption2'}
										fontWeight={500}
										color={theme.palette.black[1]}
										display={'block'}
										textAlign={'center'}
										mt={'4px'}
									>
										{item.periodText}
									</Typography>
								</Box>
							</StyledVoteAwardBox>
						</Grid>
					))}
					<Grid item md={6} pl={'28px'} width={'100%'}>
						<StyledVotePeriodBox matchUpMd={matchUpMd}>
							<ResponsiveImage
								src={images.SmaVotePeriodMobile}
								alt={'vote_period'}
								width={256}
								height={175}
							/>

							<Divider sx={{ mt: '8px', borderColor: theme.palette.grey[6] }} />

							<Box mt={'20px'} alignSelf={'center'}>
								<Typography
									variant={'caption7'}
									fontWeight={400}
									color={theme.palette.grey[2]}
									display={'block'}
									textAlign={'center'}
								>
									결과발표
								</Typography>
								<Typography
									variant={'caption2'}
									fontWeight={500}
									color={theme.palette.black[1]}
									display={'block'}
									textAlign={'center'}
									mt={'4px'}
								>
									다음 달 1주차
								</Typography>
							</Box>
						</StyledVotePeriodBox>
					</Grid>
				</Grid>

				<Grid container>
					<Typography
						variant={'caption5'}
						color={theme.palette.grey[2]}
						mt={'12px'}
						ml={'28px'}
						sx={{ cursor: 'pointer', textDecoration: 'underline' }}
						onClick={handleOpenPolicyModal}
					>
						투표 정책 자세히 알아보기 >>
					</Typography>
				</Grid>
			</Container>
		</Box>
	);
};

export default Sma05Component;
