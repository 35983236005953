import {
	Box,
	ButtonBase,
	ClickAwayListener,
	Container,
	Fade,
	Grid,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { filter, find, has } from 'lodash';
import { Fragment, useCallback, useState } from 'react';

import { ResponsiveImage } from 'components';

import { artistInfo1, artistInfo2 } from 'data/artist-info';

import images from 'assets/images';

// ----------------------------------------------------------------------

const StyledArtistGridItem = styled(Grid)(({ theme }) => ({}));

const StyledArtistNameBox = styled(Box)(({ theme }) => ({
	padding: '16px 12px'
}));

const StyledArtistLinkBox = styled(Box)(({ theme }) => ({}));

const StyledArtistWebsiteIconButton = styled(ButtonBase)(({ theme }) => ({
	borderRadius: '12px',
	backgroundColor: theme.palette.grey[5]
}));

const StyledArtistSNSButton = styled(ButtonBase)(({ theme }) => ({
	width: '100%',
	height: '36px',
	borderRadius: '12px',
	backgroundColor: theme.palette.grey[5],
	fontSize: '1.4rem',
	color: theme.palette.black[1],
	fontWeight: 500
}));

const StyledSNSItemWrapper = styled(Box)(({ theme }) => ({
	position: 'absolute',
	left: 0,
	bottom: 40,
	zIndex: 300,
	width: '100%',
	borderRadius: '12px',
	backgroundColor: theme.palette.grey[5]
}));

const StyledSNSItemButton = styled(ButtonBase)(({ theme }) => ({
	width: '100%',
	padding: '10px 0'
}));

const StyledPrizeBox = styled(Box)(({ matchUpSm }) => ({
	position: 'absolute',
	zIndex: 20,
	color: 'white',
	...(matchUpSm && {
		bottom: 16,
		right: 16,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		padding: '6px 12px',
		borderRadius: '17px',
		fontSize: '1.6rem'
	}),
	...(!matchUpSm && {
		width: '100%',
		fontSize: '1.3rem',
		fontWeight: '500',
		background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%)',
		borderTopLeftRadius: '12px',
		borderTopRightRadius: '12px',
		textAlign: 'right',
		padding: '10px'
	})
}));

// ----------------------------------------------------------------------

const Artist = ({ contentsRef, selectedContents }) => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	const [openedArtist, setOpenedArtist] = useState();

	// 웹사이트 링크 버튼 선택
	const handleClickWebsite = (url) => {
		window.open(url);
	};

	const getData = () => {
		if (selectedContents === 1) {
			return artistInfo1;
		}
		return artistInfo2;
	};

	const getSNSDisplayText = (value) => {
		switch (value) {
			case 'twitter':
				return '트위터';
			case 'youtube':
				return '유튜브';
			case 'tiktok':
				return '틱톡';
			case 'instagram':
				return '인스타그램';
			case 'facebook':
				return '페이스북';
			default:
				return '';
		}
	};

	const handleClickAway = (artist) => {
		if (openedArtist === artist) {
			setOpenedArtist();
		}
	};

	const handleOpenSNSMenu = (artist) => {
		if (openedArtist === artist) {
			setOpenedArtist();
		} else {
			setOpenedArtist(artist);
		}
	};

	const handleClickSNSItem = (url) => {
		handleClickWebsite(url);
		setOpenedArtist();
	};

	const renderMobileMenuButton = (artist) => {
		const official = find(artist.website, { type: 'official' });
		const sns = filter(artist.website, (item) => {
			return item.type !== 'official';
		});

		return (
			<Box display={'flex'}>
				{official && (
					<Fragment>
						<StyledArtistWebsiteIconButton
							disableRipple
							onClick={() => handleClickWebsite(official.url)}
						>
							<img
								src={images.Homepage}
								alt={`${artist.name.en}_${artist.website.type}`}
								width={'36px'}
								height={'36px'}
							/>
						</StyledArtistWebsiteIconButton>
						<Box width={'6px'} />
					</Fragment>
				)}

				<Box position={'relative'} display={'flex'} flex={1}>
					<ClickAwayListener onClickAway={() => handleClickAway(artist)}>
						<StyledArtistSNSButton
							disableRipple
							onClick={() => handleOpenSNSMenu(artist)}
						>
							SNS 구경하기
						</StyledArtistSNSButton>
					</ClickAwayListener>
					<Fade in={artist.id === openedArtist?.id}>
						<StyledSNSItemWrapper>
							{sns.map((item, index) => (
								<StyledSNSItemButton
									disableRipple
									key={`${artist.name.en}-sns-${index}`}
									onClick={() => handleClickSNSItem(item.url)}
								>
									<Typography variant={'caption5'} color={theme.palette.black[1]}>
										{getSNSDisplayText(item.type)}
									</Typography>
								</StyledSNSItemButton>
							))}
						</StyledSNSItemWrapper>
					</Fade>
				</Box>
			</Box>
		);
	};

	const renderWebsiteIconButton = useCallback((web, webIndex, artist) => {
		let imgSrc = '';
		switch (web.type) {
			case 'official':
				imgSrc = images.Homepage;
				break;
			case 'twitter':
				imgSrc = images.Twitter;
				break;
			case 'youtube':
				imgSrc = images.Youtube;
				break;
			case 'tiktok':
				imgSrc = images.Tiktok;
				break;
			case 'instagram':
				imgSrc = images.Instagram;
				break;
			case 'facebook':
				imgSrc = images.Facebook;
				break;
			default:
				break;
		}

		return (
			<StyledArtistWebsiteIconButton
				key={`${artist.name.en}_Button_${webIndex}`}
				onClick={() => handleClickWebsite(web.url)}
				sx={{ ml: '12px' }}
			>
				<img
					src={imgSrc}
					alt={`${artist.name.en}_${artist.website.type}`}
					width={'32px'}
					height={'32px'}
				/>
			</StyledArtistWebsiteIconButton>
		);
	}, []);

	// ----------------------------------------------------------------------

	return (
		<Container maxWidth={'lg'} ref={contentsRef}>
			<Grid
				container
				rowSpacing={matchUpSm ? 5 : 2}
				columnSpacing={matchUpSm ? 3 : 2}
				pt={matchUpSm ? 3 : '4px'}
				px={matchUpSm ? 0 : '20px'}
			>
				{getData().map((artist, index) => (
					<Grid item xs={6} md={3} key={`artist-${artist.id}`}>
						<StyledArtistGridItem>
							{/* 후보자 정보: 이미지 */}
							<Box position={'relative'}>
								{has(artist, 'prize') && (
									<StyledPrizeBox matchUpSm={matchUpSm}>{artist.prize}</StyledPrizeBox>
								)}
								<ResponsiveImage
									src={artist.profile}
									alt={artist.name.en}
									width={matchUpSm ? 282 : 162}
									height={matchUpSm ? 260 : 160}
									wrapperSx={{
										backgroundColor: '#eee',
										borderRadius: '12px'
									}}
									imgSx={{ borderRadius: '12px' }}
								/>
							</Box>

							{/* 후보자 정보: 이름*/}
							<StyledArtistNameBox>
								<Typography
									variant={matchUpSm ? 'h14' : 'caption3'}
									color={theme.palette.black[1]}
									fontWeight={700}
									display={'block'}
								>
									{artist.name.ko}
								</Typography>
								<Typography
									variant={matchUpSm ? 'caption3' : 'caption6'}
									color={theme.palette.black[1]}
									display={'block'}
									mt={matchUpSm ? '8px' : '4px'}
								>
									{artist.name.en}
								</Typography>
							</StyledArtistNameBox>
							{/*	후보자 정보: 관련 링크 */}
							{matchUpSm ? (
								<StyledArtistLinkBox>
									{artist.website.map((web, webIndex) =>
										renderWebsiteIconButton(web, webIndex, artist)
									)}
								</StyledArtistLinkBox>
							) : (
								renderMobileMenuButton(artist)
							)}
						</StyledArtistGridItem>
					</Grid>
				))}
			</Grid>
		</Container>
	);
};

export default Artist;
