import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import {
	FancastRootView,
	FancastServiceView,
	FancastPrivacyView,
	FancastAdView,
	DreamRookieRootView,
	SmaMonthRootView,
	NotFoundView
} from 'views';

// ----------------------------------------------------------------------

const RouteConfig = () => {
	const { pathname } = useLocation();

	// 라우터 경로에 따른 Document Title 설정
	useEffect(() => {
		let title = 'Fancast';
		switch (pathname) {
			case '/rookie':
				title = '드림콘서트 드림루키';
				break;
			case '/sma/month':
			case '/sma/month/about':
				title = '서울가요대상 이달의 아티스트';
				break;
			case '/app-ads.txt':
			default:
				break;
		}
		document.title = title;
	}, [pathname]);

	// ----------------------------------------------------------------------

	return (
		<Routes>
			<Route path={'/'} element={<FancastRootView />} />
			{/* 팬캐스트 이용약관 */}
			<Route path={'/service'} element={<FancastServiceView />} />
			<Route path={'/privacy'} element={<FancastPrivacyView />} />

			{/* 팬캐스트 ads */}
			<Route path={'/app-ads.txt'} element={<FancastAdView />} />

			{/* 드림콘서트 드림루키 */}
			<Route path={'/rookie'} element={<DreamRookieRootView />} />

			{/* SMA 월간투표 */}
			<Route path={'/sma/month'} element={<SmaMonthRootView />} />
			<Route path={'/sma/month/about'} element={<SmaMonthRootView />} />

			<Route path={'*'} element={<NotFoundView />} />
		</Routes>
	);
};

export default RouteConfig;
