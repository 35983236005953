import {
	Box,
	Container,
	Grid,
	Stack,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

import { VOTE_POLICY } from 'views/sma/constants';

// ----------------------------------------------------------------------

const StyledPolicyCard = styled(Box)(({ theme, matchUpMd }) => ({
	backgroundColor: theme.palette.grey[5],
	...(matchUpMd && {
		padding: 36,
		borderRadius: 24
	}),
	...(!matchUpMd && {
		padding: 20,
		borderRadius: 20
	})
}));

// ----------------------------------------------------------------------

const Sma04Component = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	if (matchUpMd) {
		return (
			<Box sx={{ pt: '80px', pb: '40px' }}>
				<Container maxWidth={1200}>
					<Grid container>
						<Grid item display={'flex'} alignItems={'center'}>
							<img src={images.SmaSpeaker} alt={'ic_speaker'} width={36} height={36} />
							<Typography variant={'h7'} ml={1}>
								투표 정책
							</Typography>
						</Grid>

						<Grid container mt={2}>
							<Grid item md={7}>
								<Typography
									variant={'caption2'}
									color={theme.palette.grey[1]}
									fontWeight={500}
								>
									서울가요대상 이달의 아티스트는 재화를 사용하여 투표하실 수 있습니다.
									<br />
									팬캐스트의 재화, 골드하트는 팬캐스트 앱 내 ‘스토어' 메뉴에서 획득하거나
									구매할 수 있습니다.
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid container columnSpacing={'24px'} pt={'28px'}>
						{VOTE_POLICY.map((item) => (
							<Grid item md={6} key={`vote_policy_card_${item.id}`}>
								<StyledPolicyCard matchUpMd={matchUpMd}>
									<Stack flexDirection={'row'} alignItems={'flex-end'} mb={'4px'}>
										<Typography
											variant={'caption1'}
											fontWeight={500}
											color={theme.palette.black[1]}
										>
											{item.title}
										</Typography>
										<Typography
											variant={'caption3'}
											fontWeight={500}
											color={theme.palette.black[1]}
										>
											{item.subtitle}
										</Typography>
									</Stack>

									<Stack
										flexDirection={'row'}
										alignItems={'center'}
										justifyContent={'flex-end'}
									>
										<Typography
											variant={'caption1'}
											fontWeight={700}
											color={theme.palette.black[1]}
										>
											{item.prefixValueText}
										</Typography>
										<Typography
											variant={'h10'}
											fontWeight={700}
											color={theme.palette.black[1]}
											ml={'4px'}
											mr={'12px'}
										>
											{item.valueText}
										</Typography>
										<img
											src={item.image}
											alt={`ic_policy_${item.id}`}
											{...item.imageSize.md}
										/>
									</Stack>
								</StyledPolicyCard>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>
		);
	}

	return (
		<Box sx={{ pt: '50px', pb: '30px', px: '20px' }}>
			<Container maxWidth={1200}>
				<Grid container>
					<Grid item display={'flex'} alignItems={'center'}>
						<img src={images.SmaSpeaker} alt={'ic_speaker'} width={20} height={20} />
						<Typography variant={'h13'} ml={1}>
							투표 정책
						</Typography>
					</Grid>
				</Grid>

				<Grid container mt={'12px'} pl={'28px'}>
					<Grid item md={7}>
						<Typography
							variant={'caption4'}
							color={theme.palette.grey[1]}
							fontWeight={500}
						>
							서울가요대상 이달의 아티스트는 재화를 사용하여 투표하실 수 있습니다.
							<br />
							팬캐스트의 재화, 골드하트는 팬캐스트 앱 내 ‘스토어' 메뉴에서 획득하거나
							구매할 수 있습니다.
						</Typography>
					</Grid>
				</Grid>

				<Grid container rowSpacing={'12px'} pt={'20px'} pl={'28px'}>
					{VOTE_POLICY.map((item) => (
						<Grid item xs={12} key={`vote_policy_card_${item.id}`}>
							<StyledPolicyCard matchUpMd={matchUpMd}>
								<Stack flexDirection={'row'} alignItems={'flex-end'} mb={'13px'}>
									<Typography
										variant={'caption3'}
										fontWeight={500}
										color={theme.palette.black[1]}
									>
										{item.title}
									</Typography>
									<Typography
										variant={'caption5'}
										fontWeight={500}
										color={theme.palette.black[1]}
									>
										{item.subtitle}
									</Typography>
								</Stack>

								<Stack
									flexDirection={'row'}
									alignItems={'center'}
									justifyContent={'flex-end'}
								>
									<Typography
										variant={'caption3'}
										fontWeight={700}
										color={theme.palette.black[1]}
									>
										{item.prefixValueText}
									</Typography>
									<Typography
										variant={'h12'}
										fontWeight={700}
										color={theme.palette.black[1]}
										ml={'2px'}
										mr={'8px'}
									>
										{item.valueText}
									</Typography>
									<img
										src={item.image}
										alt={`ic_policy_${item.id}`}
										{...item.imageSize.sm}
									/>
								</Stack>
							</StyledPolicyCard>
						</Grid>
					))}
				</Grid>
			</Container>
		</Box>
	);
};

export default Sma04Component;
