import {
	Box,
	ButtonBase,
	Container,
	ClickAwayListener,
	Fade,
	styled,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';

import images from 'assets/images';

import { ResponsiveImage } from 'components';
import { APP_FANCAST_VOTE_MAIN_URL } from 'constants/common';

const StyledBannerContainer = styled(Container)(({ theme }) => ({}));

const StyledVoteButton = styled(ButtonBase)(({ theme, matchUpSm }) => ({
	backgroundColor: theme.palette.purple[2],
	width: '100%',
	height: matchUpSm ? '60px' : '57px',
	borderBottomLeftRadius: matchUpSm ? '12px' : 0,
	borderBottomRightRadius: matchUpSm ? '12px' : 0
}));

const StyledAppItemWrapper = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 64,
	left: 0,
	zIndex: 9999,
	width: '100%',
	backgroundColor: 'rgba(30, 32, 95, 0.9)',
	borderRadius: '12px'
}));

const StyledAppItemButton = styled(ButtonBase)(({ theme }) => ({
	width: '100%',
	padding: '20px 0'
}));

const TestAdsLeftBox = styled(Box)(({ theme }) => ({
	position: 'absolute',
	left: '-210px',
	width: '160px',
	height: '600px',
	border: '1px solid black',
	zIndex: 9999
}));

const Banner = ({ scrollY }) => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	const [isOpen, setIsOpen] = useState(false);

	const handleOpenAppItem = () => {
		if (isMobile) {
			handleClickApp();
		} else {
			setIsOpen(!isOpen);
		}
	};

	const handleClickAway = () => {
		if (!isMobile) {
			setIsOpen(false);
		}
	};

	const handleClickApp = (type) => {
		if (isMobile) {
			window.open(APP_FANCAST_VOTE_MAIN_URL);
		} else {
			let url = '';
			if (type === 'android') {
				url = 'https://play.google.com/store/apps/details?id=com.contentsmadang.fancast';
			} else {
				url = 'https://apps.apple.com/kr/app/id1641638840';
			}
			window.open(url);
		}
		setIsOpen(false);
	};

	return (
		<StyledBannerContainer maxWidth={'lg'}>
			<Box position={'relative'} mt={matchUpSm ? '20px' : 0}>
				{/* 상단 메인 배너 */}
				<ResponsiveImage
					src={matchUpSm ? images.DreamRookiePrizePc : images.DreamRookiePrizeMobile}
					alt={'드림루키_투표_결과'}
					width={matchUpSm ? 1200 : 375}
					height={matchUpSm ? 670 : 775}
					wrapperSx={{
						borderTopLeftRadius: '12px',
						borderTopRightRadius: '12px'
					}}
					imgSx={{
						borderTopLeftRadius: matchUpSm ? '12px' : 0,
						borderTopRightRadius: matchUpSm ? '12px' : 0
					}}
				/>
			</Box>

			<Box position={'relative'} display={'flex'} flex={1}>
				<ClickAwayListener onClickAway={handleClickAway}>
					<StyledVoteButton
						matchUpSm={matchUpSm}
						disableRipple
						onClick={handleOpenAppItem}
					>
						<Typography
							variant={matchUpSm ? 'caption2' : 'caption3'}
							fontWeight={700}
							color={theme.palette.white[1]}
						>
							투표결과 보러가기
						</Typography>
						<Fade in={isOpen}>
							<StyledAppItemWrapper>
								<StyledAppItemButton
									disableRipple
									onClick={() => handleClickApp('android')}
								>
									<Typography
										variant={matchUpSm ? 'caption2' : 'caption3'}
										color={theme.palette.white[1]}
									>
										Android
									</Typography>
								</StyledAppItemButton>
								<StyledAppItemButton disableRipple onClick={() => handleClickApp('ios')}>
									<Typography
										variant={matchUpSm ? 'caption2' : 'caption3'}
										color={theme.palette.white[1]}
									>
										ios
									</Typography>
								</StyledAppItemButton>
							</StyledAppItemWrapper>
						</Fade>
					</StyledVoteButton>
				</ClickAwayListener>
			</Box>
		</StyledBannerContainer>
	);
};

export default Banner;
