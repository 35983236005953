import {
	Box,
	ButtonBase,
	Container,
	styled,
	useMediaQuery,
	useTheme
} from '@mui/material';

import { ContentsData } from 'views/dream-concert/constants';

const StyledBox = styled(Box)(({ theme }) => ({
	position: 'sticky',
	top: 0,
	zIndex: 500,
	backgroundColor: 'white'
}));

const StyledContentsBox = styled(Box)(({ theme, matchUpSm }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '70px'
}));

const MainContent = ({ selectedContents, handleClickContents }) => {
	const theme = useTheme();
	const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<StyledBox>
			<Container maxWidth={'lg'} sx={{ mt: matchUpSm ? '50px' : 0 }}>
				<StyledContentsBox matchUpSm={matchUpSm}>
					{ContentsData.map((item, index) => (
						<ButtonBase
							key={`Contents-${item.value}`}
							className={
								item.value === selectedContents
									? matchUpSm
										? 'SubIndexItem-Active'
										: 'M-SubIndexItem-Active'
									: matchUpSm
									? 'SubIndexItem-Inactive'
									: 'M-SubIndexItem-Inactive'
							}
							onClick={() => handleClickContents(item.value)}
							disableRipple
						>
							{item.text}
						</ButtonBase>
					))}
				</StyledContentsBox>
			</Container>
		</StyledBox>
	);
};

export default MainContent;
